/* FakePage.module.css */
.fakePage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  /* display: grid;
    
    grid-template-columns: repeat(6, 1fr); */
}

.imageBar {
  display: flex;
  flex-flow: column nowrap;
  /* width: 1fr; */
  overflow: hidden;
  /* height: 60px; */
  width: 100%;
  position: relative;
  transition: all 2s ease-out;

}

.imageColumn {
  /* flex: none; */
}

.articleContainer {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100vh; */
  width: 100%;
  justify-content: center;
  /* width: 20px; */
  align-items: center;
  /* position: absolute; */
  max-height: 1px;
  /* top: 0; */
  overflow: hidden;
  /* transition: all 2s ease-out; */
}

.show .articleContainer {
  max-height: 300px;
  justify-content: flex-start;
  flex-shrink: 0;
}

.headerImage {
  width: 100%;
  height: 120px;
  /* Additional styles */
  flex-shrink: 0;
  
}

.imageImg {
  width: 100%;
  /* max-height: 120px; */
}

.imgCont {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-height: 100px; */
}

.show .imgCont {
height: 80px;
}

.show.imageBar {
  flex-shrink: 0;
}