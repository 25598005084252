.kotzIcon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  height: clamp(80px, 14vh, 140px);
  display: flex;
  cursor: pointer;
  transition: scale 0.1s ease-out;

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.7));
  z-index: 10;

  :hover {
    scale: 1.05;
  }
}

.layoutNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  margin: 1em;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  justify-content: center;
  align-items: center;

  & .hamburger {
    cursor: pointer;
    border-radius: 8px;
    width: 37px;
    height: 37px;
    background-color: var(--color-white);
    display: flex;
    flex-flow: column nowrap;
    gap: 2px;
    padding: 6px 6px;
    justify-content: center;
    align-items: stretch;

    

    & > div {
      /* height: 3px; */
      /* width: 100%; */
      background-color: var(--color-black);
      flex-shrink: 0;
      flex-grow: 1;
      border-radius: 2px;
    }
  }

  & hgroup {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    color: var(--color-white);
    font-size: var(--fs-600);
    font-family: var(--ff-david-vc);

    & h2 {
      text-orientation: sideways;
      writing-mode: vertical-rl;
    }
  }
}

/* Desktop */
@media (min-aspect-ratio: 1) {
  nav.layoutNav {
    display: none;
  }
}
