.kotzFab {
    position: fixed;
    bottom: 20px;
    left: 20px;
    height: 5%;
    display: flex;
    cursor: pointer;
    transition: scale 0.1s ease-out;

    filter: drop-shadow(0 0 5px black);

    :hover {
        scale: 1.05;
    }
}

