svg {
  /* width: 30px;
  height: 30px; */
}

.accordion {
  background-color: #eee;
  color: var(--color-white);
  /* text-shadow: 0 0 12px black; */
  cursor: pointer;
  /* padding: 18px; */
  width: 100%;
  border: none;
  text-align: right;
  outline: none;
  font-size: var(--fs-600);
  font-family: var(--ff-david-vc);
  font-weight: 500;
  /* transition: 0.4s; */

  flex-grow: 1;
  /* background-image: url(../images/tiger0.png); */
}

.accordion:hover,
.accordion.active {
  background-color: #ccc;
}

.accordion.active {
  /* color: red; */
  top: 0;
  position: sticky;
}

.panel {
  /* padding: 0 18px; */
  /* background-color: black; */
  color: white;
  font-size: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  transition-property: max-height, max-width, padding;
}

.panel.active {
  max-height: 100vh; /* Adjust as needed for your content */
  overflow-y: scroll;
}

.panel {
  color: #222;
  text-wrap: balance;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* gap: 2em; */

  &.scrolled {
    background-color: red;

    .topTop  {
      background-color: green;
    }
  }
  

  section.articleWrapper {
    /* max-width: 600px; */
    /* max-width: 100%; */
    display: flex;
    flex-flow: column nowrap;
    align-items: start;
    justify-content: center;
    flex-shrink: 0;
    z-index: 2;

    header {
      /* display: flex;
      flex-flow: column nowrap;
      gap: 1em; */
      h1 {
        /* font: bold 4em/0.8em var(--typeface-serif); */
      }

      h2 {
        /* font: normal 1.5em/1em var(--typeface-serif); */
      }

      h3 {
        /* font: normal 0.8em/1em var(--typeface-sans); */
      }
    }

    aside {
      background-color: #4b875c;
      display: flex;
      flex-flow: column nowrap;
      gap: 1em;
      padding: 2em;
      line-height: 1em;
      margin-inline: -18px;

      h1 {
        font: bold 1em/1em "Hadassa";
      }
      main {
        display: flex;
        flex-flow: column nowrap;
        gap: 1em;
        padding-inline-start: 1em;
        article {
          h1 {
            font: bold 1em/1em "Hadassa";
            display: inline-block;
            margin-inline-end: 1em;
          }

          p {
            display: inline;
            font: normal 0.8em "DavidVC";

            a {
              font: unset;
              color: unset;
              text-decoration: underline 2px;
              text-underline-offset: 3px;
            }
          }
        }
      }
    }
  }
}

.accordionContainer {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  /* height: 90vh; */
  background-color: var(--color-black);
  transition: all 0.1s ease-out;

  & > .toTop {
    position: absolute;
    z-index: 3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 20px auto;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    /* filter: drop-shadow(0 0 3px black); */
    box-shadow: 0px 1px 8px 2px rgb(0 0 0 / 3%);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
    
    &.scrolled {
      opacity: 1;
      cursor: pointer;
      pointer-events: all;
    }

    & > .topArrow {
      filter: none;
      margin-block-end: 2px;
      /* width: 80%; */
    }
  }

  & .accordion {
    display: flex;
    position: relative;
    background-color: var(--color-white);
    color: var(--color-black);

    .active& {
      background-color: var(--color-black);
    }

    &:hover {
      & .bgContent {
        margin-left: var(--outside-img-margin-left, 400px);
        margin-top: auto;
      }
    }

    & .bgContent {
      position: absolute;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      background-image: var(
        --outside-img-horizontal-url,
        url(../images/tiger0.png)
      );

      height: 100%;
      width: 100%;
      flex-grow: 1;
      left: 0;

      z-index: 1;

      transition: all 0.25s ease-out;
    }

    & .textContent {
      align-items: center;
      /* width: 100%; */
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      bottom: auto;
      left: 0;
      padding: 0.6em;
      /* background-color: var(--color-white); */
      /* color: var(--color-black); */
      gap: 0.5em;

      .active & {
        right: 0;
      }

      & h1 {
        font-family: var(--ff-david-vc);
        font-size: var(--fs-500);
        font-weight: 700;
      }

      & h2 {
        font-size: var(--fs-400);
        line-height: 1;
        font-family: var(--ff-narkis-yair);

        &::before {
          content: ">";
          margin-inline-end: 0.5em;
        }
      }

      & h3 {
        color: var(--color-green);
        font-family: var(--ff-narkis-yair);
        line-height: 1.1;
        display: none;
      }
    }
  }
}

/* Mobile */
@media (max-aspect-ratio: 1) {
  .accordionContainer {
    & .accordion {
      overflow: auto;

      .active& {
        color: var(--color-white);
        position: absolute;
        top: 0;
        z-index: 2;
        height: 2.5em;
      }

      .articleIsOpen:not(.active)& {
        display: none;
        /* background-color: red; */
      }

      & .bgContent {
        .active & {
          margin-left: 100%;
        }
      }

      & .textContent {
        height: 100%;
        text-align: end;
        justify-content: end;
        max-width: 90%;

        .articleIsOpen & {
          max-width: 85%;
          text-align: start;
          justify-content: start;
        }

        & h2 {
          white-space: nowrap;
        }

        & h3 {
          display: none;
        }
      }
    }
  }
}

/* Desktop */
@media (min-aspect-ratio: 1) {
  .accordionContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 100%;

    .accordion {
      flex-grow: 1;
      overflow: hidden;
      height: 100%;
      width: auto;
      writing-mode: sideways-lr;
      /* color: transparent; */
      .active& {
        top: auto;
        display: flex;
        flex-flow: column nowrap;
        font-size: var(--fs-400);
        max-width: 16ch;
      }

      &:hover {
        & .bgContent {
          margin-left: auto;
          .isNotOpen & {
            margin-top: var(--outside-img-margin-top, 300px);
          }

          .isOpen & {
            margin-top: 0;
          }
        }
      }
      & .bgContent {
        background-image: var(
          --outside-img-vertical-url,
          url(../images/tiger0.png)
        );

        .active & {
          position: relative;
          max-height: 37%;
          /* width: auto; */
        }
      }

      & .textContent {
        flex-flow: column nowrap;
        bottom: 0;
        top: auto;
        left: auto;

        .active & {
          position: relative;
          color: white;
        }

        & h3 {
          .isOpen & {
            display: unset;
          }
        }
      }
    }
  }

  .panel {
    height: 100%;
    max-width: 0;
    padding-inline: 0;

    &.scrolled {
      /* background-color: red; */
      /* height: 1%; */
    }
  }

  .panel.active {
    /* Adjust as needed for your content */
    max-width: clamp(45ch, 60vw, 90ch);
    overflow-y: scroll;
    max-height: 100%;
    /* padding-inline: 16px; */
  }
}

/* No Hover */
/* Portrait */
.touch {
  & .accordionContainer {
    & > .accordion {
      &.articleIsNotOpen {
        &:hover {
          & .bgContent {
            margin-left: auto;
          }
        }
      }

      & > .bgContainer {
        white-space: nowrap;
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: end;
        z-index: 1;
        overflow-x: auto;
        overflow-y: hidden;

        & > .bgSpacer {
          display: inline-block;
          height: 100%;
          width: 90%;
          .active & {
            display: none;
          }
        }

        & > .bgContent {
          /* position: absolute; */
          position: static;
          display: inline-block !important;

          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;

          background-image: var(
            --outside-img-horizontal-url,
            url(../images/tiger0.png)
          );

          height: 100%;
          width: 100%;
          flex-grow: 1;
          left: 0;

          z-index: 1;

          transition: all 0.25s ease-out;

          .active & {
            position: absolute;
          }
        }
      }
    }
  }
}

/* Landscape */
@media (min-aspect-ratio: 1) {
  .touch {
    & .accordionContainer {
      & > .accordion {
        &.articleIsNotOpen {
          &:active {
            & .bgContent {
              margin-top: auto;
            }
          }
        }
        & > .bgContainer {
          white-space: normal;
          overflow-x: hidden;
          overflow-y: auto;

          & > .bgSpacer {
            width: 100%;
            height: 80%;
          }
        }
      }
    }
  }
}
