@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Cursive.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NarkisYair";
  src: url("./narkis_yair_fontef/NarkissYair-Regular-TRIAL.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NarkisYair";
  src: url("./narkis_yair_fontef/NarkissYair-Light-TRIAL.woff2") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Black.woff2")
    format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-BoldItalic+.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-MediumItalic+.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "KotzDisplay";
  src: url("./shira_saar/Shira_Saar_Display.woff2") format("woff2");
}

@font-face {
  font-family: "KotzText";
  src: url("./shira_saar/Shira_Saar_text-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "KotzText";
  src: url("./shira_saar/Shira_Saar_text-Medium_Green-SVG.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Alpha Bravo";
  src: url("./shira_saar/Shira_Saar_text-Medium_Green-SVG.woff2") format("woff2");
  font-weight: 500;
}

